import { toZonedTime, format } from 'date-fns-tz'
import { timeZone } from '../constants'
import type { ZodNumberDef } from 'zod'
import type { Timestamp } from 'firebase-admin/firestore'

export function shallowEquals(
	a: Record<string, string> | string | boolean | number | undefined,
	b?: Record<string, string> | string | boolean | ZodNumberDef | undefined,
	ignoreFields?: string[]
): boolean {
	if (
		typeof a === 'string' ||
		typeof a === 'boolean' ||
		typeof a === 'number' ||
		typeof a === 'undefined' ||
		typeof b === 'string' ||
		typeof b === 'boolean' ||
		typeof b === 'number' ||
		typeof b === 'undefined'
	) {
		return a === b
	}

	const bKeys = Object.keys(b)
		.filter((key) => !ignoreFields?.includes(key))
		.sort()
	const aKeys = Object.keys(a)
		.filter((key) => !ignoreFields?.includes(key))
		.sort()
	if (bKeys.length !== aKeys.length) return false
	return aKeys.every((keyA, i) => {
		if (Array.isArray(b[bKeys[i]])) {
			if (!Array.isArray(a[keyA])) {
				return false
			}
			// WARNING:
			// This only works checks equality of primitive arrays.
			// If either array is non-empty for more complex arrays, equality will not be satisfied

			if (b[bKeys[i]].length !== a[keyA].length) return false

			const sortedA = [...a[keyA]].sort()
			return [...b[bKeys[i]]].sort().every((item, index) => shallowEquals(sortedA[index], item))
		}

		return shallowEquals(a[keyA], b[bKeys[i]])
	})
}

const vocales = ['a', 'e', 'i', 'o', 'u', 'y', 'æ', 'ø', 'å']

export function transformWord(
	word: string,
	options: {
		plural?: boolean
		lowerCase?: boolean
		capitalize?: boolean
	}
): string {
	const lastChar = word.slice(-1)
	const isVocale = vocales.includes(lastChar)
	let result = word
	if (options.plural) {
		result = isVocale ? word + 'r' : word + 'er'
	}
	if (options.lowerCase) {
		result = result.toLowerCase()
	}

	if (options.capitalize) {
		if (options.lowerCase) throw new Error('Cannot have uppercase and lowercase')
		result = result[0].toUpperCase() + result.slice(1)
	}
	return result
}

export function convertToDayText(weekDay: number): string {
	switch (weekDay) {
		case 0:
			return 'Mandager'
		case 1:
			return 'Tirsdager'
		case 2:
			return 'Onsdager'
		case 3:
			return 'Torsdager'
		case 4:
			return 'Fredager'
		case 5:
			return 'Lørdager'
		case 6:
			return 'Søndager'
		default:
			return ''
	}
}

export function getFormattedDate(fieldValue?: Timestamp): string {
	const date = fieldValue?.toDate()
	if (date) {
		return format(toZonedTime(date, timeZone), 'yyyy-MM-dd HH:mm')
	}
	return 'N/A'
}
